<template>
  <div style="margin: 41px 40px">
    <Modal
      width="40%"
      v-if="isModalDetail"
      @close="() => (isModalDetail = false)"
    >
      <h1 class="modal-title">Detail Data User</h1>

      <div class="grid-form">
        <div class="data-box">
          <h5 class="label">No. Invoice</h5>
          <h5 class="label-2">{{ dataDetail.no_invoice }}</h5>
        </div>
        <div class="data-box">
          <h5 class="label">No. Invoice</h5>
          <h5 class="label-2">{{ dataDetail.datetime_purchased }}</h5>
        </div>
      </div>
      <div class="data-box">
        <h5 class="label">Nama Lengkap</h5>
        <h5 class="label-2">{{ dataDetail.user_name }}</h5>
      </div>
      <div class="grid-form">
        <div class="data-box">
          <h5 class="label">No. Handphone</h5>
          <h5 style="display: flex" class="label-2">
            (+62) {{ dataDetail.phone }}
          </h5>
        </div>
        <div class="data-box">
          <h5 class="label">Email</h5>
          <h5 class="label-2">{{ dataDetail.email }}</h5>
        </div>
      </div>
      <div class="grid-form">
        <div class="data-box">
          <h5 class="label">Voucher</h5>
          <h5 class="label-2">{{ dataDetail.voucher }}</h5>
        </div>
        <div class="data-box">
          <h5 class="label">Nominal Transaksi</h5>
          <h5 class="label-2">
            IDR.{{ dataDetail.normal_price }} - IDR.{{ dataDetail.discount }} -
            IDR.{{ dataDetail.current_price }} = IDR.
            {{
              dataDetail.normal_price -
              dataDetail.discount -
              dataDetail.current_price
            }}
          </h5>
        </div>
      </div>
      <div v-if="dataDetail.status === 'CANCEL'" class="data-box">
        <h5 class="label">Alasan</h5>
        <h5 class="label-2">{{ dataDetail.notes }}</h5>
      </div>
    </Modal>

    <Modal
      width="40%"
      v-if="isModalDownload"
      @close="() => (isModalDownload = false)"
    >
      <h1 class="modal-title">Konfirmasi</h1>

      <p style="margin: 20px 0px" class="detail-value">
        Apakah Anda yakin akan mengunduh data transaksi?
      </p>
      <div class="btn-container-2">
        <export-excel
          style="cursor: pointer"
          class="btn-simpan-2"
          :data="dowload"
          worksheet="sheet 1"
          name="transaksi-event-1.xls"
        >
          YA, UNDUH
        </export-excel>

        <button
          style="margin-left: 10px"
          @click.prevent="getDownload(false)"
          class="color-text-gray btn-batalkan"
        >
          BATALKAN
        </button>
      </div>
    </Modal>

    <div class="page">
      <h5 class="text-page" style="margin-right: 10px">Finance</h5>
      <Arrow :fill="'#4456b4'" :direct="'left'" />
      <h5 class="text-page" style="margin: 10px">(B2C) Event</h5>
      <Arrow :fill="'#4456b4'" :direct="'left'" />
      <h5 class="text-page" style="margin-left: 10px">
        {{
          this.categorys
            .split("-")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
        }}
      </h5>
      <Arrow :fill="'#4456b4'" :direct="'left'" />
      <h5 class="text-page color-text-gray long-text" style="margin-left: 10px" v-if="this.data[0]">
        {{ this.data[0].event_name }}
      </h5>
      <button
        @click.prevent="getDownload(true)"
        class="ml-auto color-text-gray btn-download"
      >
        <Download :width="20" :height="20" />
        Download
      </button>
    </div>

    <div class="body">
      <div class="container-search">
        <input
          type="text"
          placeholder="Ahmad Damar"
          class="input-search"
          v-model="isSearch"
        />
        <date-range-picker
          :ranges="false"
          :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
          v-model="dateRange"
          :autoApply="true"
          :linkedCalendars="true"
          class="no-border"
          :append-to-body="false"
          :control-container-class="true"
          @start-selection="logEvent('event: startSelection', $event)"
          @finish-selection="logEvent('event: finishSelection', $event)"
        >
          <template v-slot:input="{ startDate, endDate }">
            <div class="custom-control-container">
              {{
                startDate.toLocaleDateString("id-ID", {
                  year: "numeric",
                }) ==
                endDate.toLocaleDateString("id-ID", {
                  year: "numeric",
                })
                  ? startDate
                      .toLocaleDateString("id-ID", {
                        day: "2-digit",
                        month: "short",
                      })
                      .replace(/\//g, "-")
                  : startDate
                      .toLocaleDateString("id-ID", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })
                      .replace(/\//g, "-")
              }}
              -
              {{
                endDate
                  .toLocaleDateString("id-ID", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })
                  .replace(/\//g, "-")
              }}
            </div>
          </template>
        </date-range-picker>
        <button class="btn-serach" @click.prevent="getSearch">TERAPKAN</button>
      </div>
      <div class="container-tabel">
        <table class="tabel borderless-table">
          <thead style="border: 2px solid black">
            <tr>
              <th class="table-head border-tabel">No. Invoice</th>
              <th class="table-head border-tabel">Waktu Pembelian (WIB)</th>
              <th class="table-head border-tabel">Nama</th>
              <th class="table-head border-tabel">Program</th>

              <th class="table-head border-tabel">Voucer</th>

              <th class="table-head border-tabel">Nominal Transaksi</th>
              <th class="table-floting border-tabel">Action</th>
            </tr>
          </thead>
          <tbody v-if="data.length">
            <tr
              v-for="(result, key) in data"
              :key="key"
              :style="{
                backgroundColor: key % 2 === 1 ? '#f5f6f9' : 'transparent',
              }"
            >
              <td class="table-body tbody">{{ result.no_invoice }}</td>
              <td class="table-body tbody">{{ result.datetime_purchased }}</td>
              <td class="table-body tbody">{{ result.user_name }}</td>
              <td class="table-body tbody">{{ result.event_name }}</td>

              <td class="table-body tbody">{{ result.voucher }}</td>
              <td class="table-body">
                <div
                  class="d-flex"
                  style="flex-direction: column; text-align: left"
                >
                  <div class="d-flex td-transaksi">
                    <p class="tbody tbody-color-gray">Normal Price</p>
                    <p class="tbody ml-auto td-transaksi-2">
                      IDR {{ result.normal_price }}
                    </p>
                  </div>
                  <div class="d-flex td-transaksi">
                    <p class="tbody tbody-color-gray">Discount</p>
                    <p class="tbody ml-auto td-transaksi-2">
                      IDR {{ result.discount }}
                    </p>
                  </div>
                  <div class="d-flex td-transaksi">
                    <p class="tbody tbody-color-gray">Current Price</p>
                    <p class="tbody tbody-color-red ml-auto td-transaksi-2">
                      IDR {{ result.current_price }}
                    </p>
                  </div>
                </div>
              </td>
              <td
                :style="{
                  backgroundColor: key % 2 === 1 ? '#f5f6f9' : '',
                }"
                class="table-floting"
              >
                <div class="warp-btn-action">
                  <button class="btn-action" @click="goToPage(result.id)">
                    UBAH
                  </button>
                  <button
                    @click.prevent="getmodal(true, result.id)"
                    class="btn-action-2"
                  >
                    LIHAT
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <p class="text-jumlah">
          Menampilkan <span style="font-weight: 700">{{ this.pagination.total_data }}</span> dari
          <span style="font-weight: 700">{{ this.pagination.total_page }}</span>
          page
        </p>
        <div class="pegenation">
          <Arrow :fill="'#4456b4'" :direct="'right'" />
          <button
            v-for="n in pagination.total_page"
            :key="n"
            class="pegenation-text color-text-blue"
            @click.prevent="NextPage(n)"
          >
            {{ n }}
          </button>
          <Arrow :fill="'#4456b4'" :direct="'left'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from "../../../../../components/icons/Arrow.vue";
import Download from "@/components/icons/Download.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Modal from "@/components/Modal.vue";
export default {
  props: ["id", "categorys", "riwayat"],
  data() {
    return {
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      data: [],
      dataDetail: null,
      pagination: null,
      isModalDetail: false,
      dowload: [],
      isModalDownload: false,
      isSearch: "",
      isStatus: "PAID",
    };
  },
  components: { Arrow, Download, DateRangePicker, Modal },
  methods: {
    async fetchTransactionEvent() {
      try {
        const response = await this.$crm_http.get(`/v1/transaction/event/${this.id}?page=0&per_page=10&status=PAID`)
        this.data = response.data.data.transaction_event;
        this.pagination = response.data.data.pagination;
      } catch (error) {
        console.error("Error fetching transaction events:", error);
      }
    },
    logEvent(name, event) {
      console.log(name, event);
      console.log(name, event);
    },
    goToPage(id) {
      this.$router.push(
        `/transaction/b2b/event/${this.categorys
          .toLowerCase()
          .replace(/\s+/g, "-")}/detail/${id}`
      );
    },
    async getmodal(e, id) {
      this.isModalDetail = e;
      if (e) {
        const response = await this.$crm_http.get(`/v1/transaction/${id}`)
        this.dataDetail = response.data.data;
      }
    },
    async getDownload(e) {
      this.isModalDownload = e;
      if (e) {
        const response = await this.$crm_http.get(`/v1/transaction/event/${this.id}?download=true&status=PAID`)
        this.dowload = response.data.data.transaction_event;
      }
    },
    async getSearch() {
      try {
  
        const response = await this.$crm_http.get(`/v1/transaction/event/${
            this.id
          }?page=0&per_page=10&status=${this.isStatus}${
            this.isSearch !== "" ? "&keyword=" + this.isSearch : ""
          }${
            this.dateRange !== ""
              ? "&date_period=" +
                this.dateRange.startDate
                  .toLocaleDateString("id-ID", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                  .replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$2-$1") +
                ":" +
                this.dateRange.endDate
                  .toLocaleDateString("id-ID", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                  .replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$2-$1")
              : ""
          }`)
        this.data =
          response.data.data.transaction_event === null
            ? []
            : response.data.data.transaction_event;
        this.pagination = response.data.data.pagination;
      } catch (error) {
        console.error("Error fetching transaction events:", error);
        // this.transactionEvent = [];
      }
    },
    async NextPage(e) {
      try {
        const response = await this.$crm_http.get(`/v1/transaction/event/${this.id}?page=${e}&per_page=10&status=PAID`)
        this.data = response.data.data.transaction_event;
        this.pagination = response.data.data.pagination;
      } catch (error) {
        console.error("Error fetching transaction events:", error);
      }
    },
  },
  mounted() {
    this.fetchTransactionEvent();
  },
};
</script>

<style scoped>
.btn-container-2 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.btn-batalkan {
  background-color: #ffffff;
  color: #7b7e8c;
  width: 156px;
  height: 44px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #d3d6e1;
}
.btn-simpan {
  background-color: #acafbf;
  color: #ffffff;
  width: 156px;
  height: 44px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #acafbf;
}
.btn-simpan-2 {
  background-color: #2d46cf;
  color: #ffffff;
  width: 156px;
  height: 44px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #acafbf;
}
.grid-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 36px 1rem;
  width: 100%;
}
.label {
  color: #7b7e8c;
  font-weight: 400;
  font-size: 14px;
}
.label-2 {
  color: #36373f;
  font-weight: 500;
  font-size: 18px;
}
.data-box {
  width: 100%;
  border: 1px solid #d3d6e1;
  text-align: left;
  border-radius: 10px;
  padding: 16px 20px;
  font-weight: 400;
  font-size: 14px;
  color: #575966;
  margin-top: 25px;
}
.td-transaksi {
  justify-content: space-between;
  margin-top: 5px;
  width: 300px;
}
.td-transaksi-2 {
  width: 100px;
}
.container-search {
  display: flex;
  gap: 15px;
  align-items: center;
}
.custom-control-container {
  font-weight: 400;
  font-size: 14px;
  color: #575966;
  border: 1px solid #d3d6e1;
  width: 233px;
  height: 48px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.long-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 160px;
}
.tbody {
  margin: 0;
  font-weight: 400;
  border-collapse: collapse;
  font-size: 14px;
}
.tbody-color-red {
  color: #b80f0a;
}
.tbody-color-gray {
  color: #7b7e8c;
}
.container-tabel {
  background-color: white;
  margin: 20px;
  overflow-x: auto;
}
.tabel {
  position: relative;
  border: none;
  width: 100%;
  border-collapse: collapse;
}
.table-head {
  min-width: 200px;
  background-color: white;
  white-space: nowrap;
  width: auto;
  padding: 10px;
}
.border-tabel {
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 2px solid #e5e7e9;
}
.table-body {
  min-width: 200px;
  height: 141px;
  white-space: nowrap;
  width: auto;
  padding: 10px;
  text-align: center;
}
.table-floting {
  white-space: nowrap;
  width: auto;
  min-width: 200px;
  text-align: center;
  position: sticky;
  right: 0;
  background-color: white;
}
.warp-btn-action {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.btn-action {
  background-color: white;
  width: 79px;
  height: 38px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #575966;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #acafbf;
}
.btn-action-2 {
  background-color: white;
  color: #2d46cf;
  width: 79px;
  height: 38px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #2d46cf;
}
.input-search {
  width: 233px;
  border: 1px solid #d3d6e1;
  height: 48px;
  border-radius: 10px;
  padding: 0px 20px;
  font-weight: 400;
  font-size: 14px;
  color: #575966;
}

.btn-serach {
  background-color: #2d46cf;
  color: white;
  width: 156px;
  height: 44px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-download {
  background-color: #ffffff;
  color: #7b7e8c;
  width: 156px;
  height: 44px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #d3d6e1;
}
.text-jumlah {
  font-size: 12px;
  font-weight: 400px;
  margin-top: 46px;
}
.pegenation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.pegenation-text {
  font-size: 14px;
  font-weight: 700;
  margin-left: 15px;
  margin-right: 15px;
}
.text-page {
  font-weight: 500;
  font-size: 16px;
  color: #162ca2;
}
.color-text-blue {
  color: #4456b4;
}
.color-text-gray {
  color: #acafbf;
}
.page {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.body {
  background: #ffffff;
  padding: 42px 22px;
  border-radius: 10px;
  height: fit-content;
}
</style>
